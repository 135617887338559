import * as React from "react"
import { PageProps, graphql } from "gatsby"

import Layout from "../components/Layout/Layout"
import Seo from "../components/seo"

type DataProps = {
  site: {
    buildTime: string
  }
}

const Datenschutzerklärung: React.FC<PageProps<DataProps>> = () => (
  <Layout>
    <Seo title="Datenschutzerklärung" />
    <div className="container">
      <h2 className="mt-3 fw-bold">1. Allgemeine Hinweise und Pflichtinformationen</h2>
      <p className="fw-bold m-0 p-0">Datenschutz</p>
      <p className="p-0">
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung. Wenn Sie diese Website benutzen, werden
        verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind
        Daten, mit denen Sie persönlich identifiziert werden können. Die
        vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und
        wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
        geschieht. Wir weisen darauf hin, dass die Datenübertragung im Internet
        (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
        kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
        nicht möglich.
      </p>
      <p className="fw-bold m-0 p-0">Hinweis zur verantwortlichen Stelle</p>
      <p>
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist:
      </p>
      <p className="m-0 p-0">Stephan Hirl Design - Einzelunternehmen</p>
      <p className="m-0 p-0">Mertsee 10</p>
      <p className="m-0 p-0">84326 Falkenberg</p>
      <p className="m-0 p-0">USt-ID-Nr.: DE335794187</p>
      <p>
        <a className="text-gray" href="mailto:info@hirlomat.com">
          info@hirlomat.com
        </a>
      </p>
      <p>Vertreten durch: Stephan Hirl</p>
      <p>
        Verantwortliche Stelle ist die natürliche oder juristische Person, die
        allein oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o.
        Ä.) entscheidet.
      </p>
      <p className="fw-bold m-0 p-0">
        Widerruf Ihrer Einwilligung zur Datenverarbeitung
      </p>
      <p>
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
        Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
        jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an
        uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung
        bleibt vom Widerruf unberührt.
      </p>
      <p className="fw-bold m-0 p-0">
        Beschwerderecht bei der zuständigen Aufsichtsbehörde
      </p>
      <p>
        Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein
        Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige
        Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
        Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen
        seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren
        Kontaktdaten können folgendem Link entnommen werden:
        https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
      </p>
      <p className="fw-bold m-0 p-0">Recht auf Datenübertragbarkeit</p>
      <p>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
        oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder
        an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen
        zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
        Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
        machbar ist.
      </p>
      <p className="fw-bold m-0 p-0">Auskunft, Sperrung, Löschung</p>
      <p>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
        das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
        personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
        Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder
        Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
        personenbezogene Daten können Sie sich jederzeit unter der im Impressum
        angegebenen Adresse an uns wenden.
      </p>
      <p className="fw-bold m-0 p-0">Widerspruch gegen Werbe-Mails</p>
      <p>
        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
        Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
        Werbung und Informationsmaterialien wird hiermit widersprochen. Die
        Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
        Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
        Spam-E-Mails, vor.
      </p>
      <h2 className="fw-bold">2. Datenerfassung auf unserer Website</h2>
      <p className="fw-bold m-0 p-0">Cookies</p>
      <p>
        Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
        richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
        Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
        sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem
        Rechner abgelegt werden und die Ihr Browser speichert. Die meisten der
        von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie
        werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies
        bleiben auf Ihrem Endgerät gespeichert bis Sie diese löschen. Diese
        Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch
        wiederzuerkennen. Sie können Ihren Browser so einstellen, dass Sie über
        das Setzen von Cookies informiert werden und Cookies nur im Einzelfall
        erlauben, die Annahme von Cookies für bestimmte Fälle oder generell
        ausschließen sowie das automatische Löschen der Cookies beim Schließen
        des Browser aktivieren. Bei der Deaktivierung von Cookies kann die
        Funktionalität dieser Website eingeschränkt sein. Cookies, die zur
        Durchführung des elektronischen Kommunikationsvorgangs oder zur
        Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z.B.
        Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6
        Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein
        berechtigtes Interesse an der Speicherung von Cookies zur technisch
        fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit
        andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens)
        gespeichert werden, werden diese in dieser Datenschutzerklärung
        gesondert behandelt.
      </p>

      <p className="fw-bold m-0 p-0">Server-Log-Dateien</p>
      <p className="m-0 p-0">
        Der Provider der Seiten erhebt und speichert automatisch Informationen
        in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns
        übermittelt. Dies sind:
      </p>
      <p className="m-0 p-0">Browsertyp und Browserversion</p>
      <p className="m-0 p-0">verwendetes Betriebssystem</p>
      <p className="m-0 p-0">Referrer URL</p>
      <p className="m-0 p-0">Hostname des zugreifenden Rechners</p>
      <p className="m-0 p-0">Uhrzeit der Serveranfrage</p>
      <p className="m-0 p-0">
        Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht
        vorgenommen.
      </p>
      <p className="mb-5 p-0">
        Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b DSGVO, der
        die Verarbeitung von Daten zur Erfüllung eines Vertrags oder
        vorvertraglicher Maßnahmen gestattet.
      </p>
    </div>
  </Layout>
)

export default Datenschutzerklärung

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
  }
`
